import { useState } from "react";
import "./FormPage.css";
import { Form } from "./Form";

export const FormPage = () => {
  const [guestCount, setGuestCount] = useState(1);
  const [guests] = useState([]);
  const [jsonGuests, setJsonGuests] = useState("");

  const updateGuest = (guest) => {
    const i = guests.findIndex((g) => g.id === guest.id);

    if (i === -1) {
      guests.push({
        ...{
          name: "",
          age: 0,
          foodAllergy: "",
          room: false,
          ride: false,
        },
        ...guest,
      });
    } else {
      guests[i] = { ...guests[i], ...guest };
    }

    setJsonGuests(JSON.stringify(guests));
  };

  return (
    <div className="form-page">
      <div className="form-page-text">Kedves Családunk/Barátunk!</div>
      <div className="form-page-text-description">
        Kérjük töltsétek ki az alábbi form-ot, ezzel is segítve a Nagy Napunk
        szervezését!
      </div>

      <div className="form-container">
        {[...Array(guestCount)].map((e, i) => (
          <Form updateGuest={updateGuest} key={i} index={i + 1} />
        ))}
      </div>

      <div
        className="add-guest-btn"
        onClick={() => {
          setGuestCount(guestCount + 1);
        }}
      >
        {" "}
        Vendég hozzáadása +
      </div>

      <form method="POST" name="contactform" className="netlify-form">
        <input type="hidden" name="form-name" value="contactForm" />
        <input
          type="text"
          name="message"
          placeholder=""
          className="hidden"
          readOnly
          value={jsonGuests}
        />

        <button className="form-submit" type="submit">
          Küldés
        </button>
      </form>
    </div>
  );
};
