import "./Place.css";

export const Place = () => {
  return (
    <div className="place-container">
      <div className="schedule-title">A helyszín megközelítése</div>
      <img className="map" src="./deak-udvarhaz-terkep.jpg" alt="terkep"></img>
    </div>
  );
};
