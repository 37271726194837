import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Landing.css";

export const Landing = () => {
  return (
    <div className="landing-container">
      <div className="names">Niki és János</div>
      <div className="date">2022. | DECEMBER | 10.</div>
      <div className="arrow">
        <FontAwesomeIcon icon={faChevronDown} className="arrow-down" />
      </div>
    </div>
  );
};
