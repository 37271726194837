import "./App.css";
import "font-awesome/css/font-awesome.min.css";

import { FormPage } from "./components/FormPage";
import { Landing } from "./components/Landing/Landing";
import { Place } from "./components/Place/Place";
import { Footer } from "./components/Footer/Footer";
import { Schedule } from "./components/Schedule/Schedule";

function App() {
  return (
    <div className="App">
      <Landing />
      <FormPage />
      <Schedule />
      <Place />
      <Footer />
    </div>
  );
}

export default App;
