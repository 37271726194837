import "./Schedule.css";

export const Schedule = () => {
  return (
    <div className="schedule-container">
      <div className="schedule-title">Program</div>
      <div className="schedule-text">
        <div className="schedule-time">16:00</div>
        Vendégvárás
      </div>
      <div className="schedule-text">
        <div className="schedule-time">17:00</div>
        Szertartás esti fényekben
      </div>
      <div className="schedule-text">
        <div className="schedule-time">17:30</div>
        Gratuláció, fotózás
      </div>
      <div className="schedule-text">
        <div className="schedule-time">19:00</div>
        Vacsora
      </div>
    </div>
  );
};
