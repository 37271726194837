import "./Form.css";
import { FormControlLabel, Checkbox, TextField } from "@mui/material";

export const Form = ({ updateGuest, index }) => {
  return (
    <div className="guest-form">
      <div className="guest-name">{index}. vendég</div>
      <div className="form-input-group input-container">
        <TextField
          id="name-input"
          label="Név"
          variant="outlined"
          required
          onChange={(e) =>
            updateGuest({
              id: index,
              name: e.target.value,
            })
          }
        />
      </div>
      <div className="form-input-group input-container">
        <TextField
          id="food-input"
          label="Étel allergia"
          variant="outlined"
          onChange={(e) =>
            updateGuest({
              id: index,
              foodAllergy: e.target.value,
            })
          }
        />
      </div>
      <div className="form-input-group input-container">
        <TextField
          id="age-input"
          label="Kor"
          helperText="18 év alatti vendég esetén"
          type="number"
          variant="outlined"
          onChange={(e) =>
            updateGuest({
              id: index,
              age: e.target.value,
            })
          }
        />
      </div>
      <div className="form-input-group input-container">
        <FormControlLabel
          label="Szeretné(n)k szállást a helyszínen"
          control={
            <Checkbox
              id="room-input"
              onChange={(e) =>
                updateGuest({
                  id: index,
                  room: e.target.checked,
                })
              }
            />
          }
        />
      </div>
      <div className="form-input-group input-container">
        <FormControlLabel
          label="Szeretnék fuvart haza"
          control={
            <Checkbox
              id="ride-input"
              onChange={(e) =>
                updateGuest({
                  id: index,
                  ride: e.target.checked,
                })
              }
            />
          }
        />
      </div>
    </div>
  );
};
